





























import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import UpDownCom from "../../components/ShareComponent/ListBottom.vue"
import List from "../../components/ShareComponent/PullDownUp.vue"
import AddressListCom from "../../components/AddressComponent/AddressComponent.vue"
import Stroage from "../../util/Storage"
import { AddressList as AddressList_ } from "../../Api/Basics/index"
import { AddressType } from "../../Type/index"
import { UserStore } from "../../store/modules/User"
import { IsPageNo } from "../../util/filter"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface AddressListType<T=string>{
    AddIcon:T;
    title:T;
    UpDown:boolean;
    PullDown:boolean;
    userId:T|number;
    token:T;
    List:AddressType[];
    UpData:{
        pageNo:number;
        pageSize:number;
    }
    UpDownBool:boolean;
    loadingShow:boolean;
    SelBool:boolean // 是否是选择地址状态

    initData():void;
    handleRefrech(data:any):void;
    handlePullDown():void;
    handleUpDown():void;
    handleUpData(bool?:boolean):void;
    handleError(data:string):void;
}

@Component({ name:"AddressList",components:{ List,UpDownCom,AddressListCom,HeadTop } })
export default class AddressList extends ZoomPage implements AddressListType{
    AddIcon = require("../../assets/icon/Basics/Add.png");
    title = "地址列表"
    UpDown = false;
    PullDown = false
    userId:number|string = ""
    token = ""
    List:AddressType[] = [];
    UpData = {
        pageNo:1,
        pageSize:100
    }
    UpDownBool = false
    loadingShow = true
    SelBool = false;

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       if( UserStore.GetAddressList.length ){
           this.List = UserStore.GetAddressList
           this.UpData.pageNo = IsPageNo(this.List.length,100)
           this.loadingShow = false
       }else{
           this.handleUpData()
       }

       // 初始化
       this.initData()
   }

   initData(){
       let { select } = this.$route.query
       let reg = /true/g
       if ( reg.test( select + "" ) ) {
           this.SelBool = true
           this.title = "选择地址"
       }else{
           this.SelBool = false
           this.title = "地址列表"
       }
   }

   handleToPath(){
       this.$router.push({
           path:"/addaddress"
       })
   }

   handleRefrech(data:any){
       if( data ){
           this.UpData.pageNo = 1
           this.handleUpData(data)
       }
   }

   handlePullDown(){
       this.PullDown = false
       this.UpData.pageNo = 1
        AddressList_({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.PullDown = true
           if( res.message.code === "200" ){
               if( res.data.length < this.UpData.pageSize ){
                   this.UpDownBool = true
               }else{
                    this.UpData.pageNo += 1
               }
               this.List = res.data
               UserStore.SetAddressList( this.List )
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }
   handleUpDown(){
       if( this.UpDownBool )return;
       this.UpData.pageNo += 1
       this.UpDown = true
       this.handleUpData()
   }

   handleUpData(bool?:boolean){
       AddressList_({
           userId:this.userId,
           token:this.token
       },this.UpData).then((res:any)=>{
           this.loadingShow = false
           this.UpDown = false
           if( res.message.code === "200" ){
               if( res.data.length < this.UpData.pageSize ){
                   this.UpDownBool = true
               }
               if( bool ){
                   this.List = res.data
               }else{
                   this.List = this.List.concat(res.data)
               }
               UserStore.SetAddressList( this.List )
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
