































import { Notify,Toast, } from "vant"
import Stroage from "../../util/Storage"
import { SetDefaultAddress,RemoveAddress } from "../../Api/Basics/index"
import { UserStore } from '../../store/modules/User'
import { Vue,Component,Prop,Emit } from "vue-property-decorator"

@Component({ name:"AddressComponent" })
export default class AddressComponent extends Vue{
    userId:string|number = ""
    token = ""
    Default = false
    loadingShow = false
    
    @Prop(Boolean)
    SelBool!:boolean

    get GetSelBool():boolean{
        return this.SelBool
    }

    @Prop(Object)
    data:any

    get GetData(){
        if( this.data.status == "default" ){
            this.Default = true
        }else{
            this.Default = false
        }
        return this.data
    }

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")  
    }

   handleToPath(e:any){
       this.$router.push({
           path:"/addaddress",
           query:{
               write:"true",
               title:"编辑地址",
               data:JSON.stringify( this.GetData )
           }
       })
       e.preventDefault();
       e.stopPropagation();
   }

   handleSelClick(e:any){
       if ( this.GetSelBool ) {
           console.log( 123 )
            UserStore.SetSelAddress( this.GetData )
            this.$router.go(-1)
            return;
        }else{
            this.handleSetDefault(e)
        }
        e.preventDefault();
        e.stopPropagation()
   }

    handleSetDefault(e:any){
        if( this.GetData.status === "default" ){
            Toast({
               message:"已是默认地址",
               icon:"none",
               className:"ToastClassName"
           })
           e.preventDefault();
           e.stopPropagation()
            return
        }
        this.loadingShow = true
        SetDefaultAddress({
            userId:this.userId,
            token:this.token
        },{
            id:this.GetData.id
        }).then((res:any)=>{
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.Refresh()
                Toast({
                   message:"设置成功",
                   icon:"none",
                   className:"ToastClassName"
               })
            }else{
                this.handleError( res.message.msg )
            }
        })
        e.preventDefault();
        e.stopPropagation()
    }
   
   handleRemoveAddress(e:any){
       this.loadingShow = true
       RemoveAddress({
           userId:this.userId,
           token:this.token
       },{
           id:this.GetData.id
       }).then( (res:any)=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               this.Refresh()
               Toast({
                   message:"删除成功",
                   icon:"none",
                   className:"ToastClassName"
               })
           }else{
               this.handleError( res.message.msg )
           }
       } )
       e.preventDefault();
       e.stopPropagation()
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

   @Emit("Refresh")
   Refresh(){
       return true
   }
}
